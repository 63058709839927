<template>
	<div class="main">
		<template  v-if="dataInfo">
			<div class="info-card-box">
				<div class="card-line-box">
					<span class="card-item-title">市场：</span>
					<span class="card-item-text">{{dataInfo.market}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">部门：</span>
					<span class="card-item-text">{{dataInfo.depName}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">岗位：</span>
					<span class="card-item-text">{{dataInfo.position}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">姓名：</span>
					<span class="card-item-text">{{dataInfo.userName}}</span>
				</div>
			</div>
			<div class="perf-info-box">
				<div class="table-title-box">
					<img style="width: 15px;height: 15px" src="../../assets/image/performance/table.png"/>
					<span class="table-title-text">{{monthArr[0]}}年{{monthArr[1]}}月绩效考核表</span>
				</div>
				<div class="table-box">
					<div class="table-title-line">
						<div class="table-cell" style="width: 35vw">指标名称</div>
						<div class="table-cell" style="width: 20vw">权重</div>
						<!--<div class="table-cell" style="width: 40vw">定义及计算方法</div>-->
						<div class="table-cell" style="width: 25vw">目标值</div>
					</div>
					<div @click="toAddInfo(index)" v-for="(item, index) in postData.contents" :key="index" class="table-line">
						<div class="table-cell" style="width: 35vw">{{item.target}}</div>
						<div class="table-cell" style="width: 20vw">{{item.weight}}%</div>
						<!--<div class="table-cell" style="width: 40vw">{{item.computeMode}}</div>-->
						<div class="table-cell" style="width: 25vw">{{item.targetValue}}</div>
					</div>
				</div>
				<div @click="toAddInfo" class="add-button">
					<img style="width: 15px;height: 15px;padding-right: 5px" src="../../assets/image/performance/show.png"/>
					<span class="add-button-text">查看考核项</span>
				</div>
				<div>
					<div class="ticket-title">附件</div>
					<div class="no-text">
						<van-uploader v-model="ticket" upload-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165994288232878081" :max-count="3" :after-read="afterRead" />
					</div>
				</div>
			</div>
			<div class="perf-info-box">
				<div class="order-status-title">审批流程</div>
				<div>
					<van-steps direction="vertical" active-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" inactive-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" active-color="#333333" :active="stepId">
						<van-step v-for="(item, index) in activities" :key="index">
							<p class="steps-title">{{item.levelName}}<span v-if="index === 0" class="active-tip">或签</span></p>
							<div class="leader-box">
								<div v-for="(item2,index2) in item.auditorList" :key="index2" class="leader-item">
									<div class="leader-item-box">
										<div class="leader-item-name-box">
											<div class="leader-item-header-img">
												<img v-if="item2.checkFlag>0" class="step-ok-img" src="../../assets/image/performance/ok.png"/>
												<img class="step-img" src="../../assets/image/performance/r.png"/>
											</div>
											<span>{{item2.auditorName}}</span>
										</div>
										<div v-if="item2.checkFlag>0" class="leader-item-time">
											<span class="leader-ok" v-if="item2.checkFlag === 1">已完成</span>
											<span class="leader-no" v-if="item2.checkFlag === 2">已退回</span>
											<span>{{item2.createTime}}</span>
										</div>
									</div>
									<div v-if="item2.checkFlag === 2" class="back-msg">退回原因：{{item2.message}}</div>
								</div>
							</div>
						</van-step>
					</van-steps>
				</div>
			</div>
			<div style="width: 100vw;height: 80px"></div>
			<div v-if="stepId < 2 && stepId + 1 === nowLevel" class="bottom-button-box">
				<div @click="returnData()" class="button-no">退回</div>
				<div @click="showOk()" class="button-ok">通过</div>
			</div>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
		<!--驳回弹窗开始-->
		<van-popup
				v-model="noShow"
				lock-scroll
				round
				:style="{ width:'80vw' }"
		>
			<div class="message-box-info-box">
				<div class="message-box-info-top-title">提示</div>
				<div class="message-box-info-top-info">确认审批退回本月绩效考核吗？</div>
				<div class="message-box-text">
					<van-field
							style="font-size: 16px;border: 1px solid #F1F1F1;margin: 0 20px 20px 20px"
							v-model="examplesData.rejectReason"
							rows="2"
							autosize
							type="textarea"
							placeholder="请输入退回原因"
					/>
				</div>
				<div class="message-box-button">
					<div @click="hideBox" class="button-cancel">取消</div>
					<div @click="toNo(3)" class="button-ok-popup">确认</div>
				</div>
			</div>
		</van-popup>
		<!--驳回弹窗结束-->
		<!--通过弹窗开始-->
		<van-popup
				v-model="okShow"
				lock-scroll
				round
				:style="{ width:'80vw' }"
		>
			<div class="message-box-info-box">
				<div class="message-box-info-top-title">提示</div>
				<div class="message-box-info-top-info">确认审批通过本月绩效考核吗？</div>
				<div class="message-box-button">
					<div @click="okShow = false" class="button-cancel">取消</div>
					<div @click="toNo(2)" class="button-ok-popup">确认</div>
				</div>
			</div>
		</van-popup>
		<!--通过弹窗结束-->
	</div>
</template>

<script>
	import { getPerfById, getCosToken, editPerf, modifyFlow } from '@/api/performance'
	import {Dialog, Toast} from 'vant';
	import COS from 'cos-js-sdk-v5'
	import { Empty } from 'vant';
	// import Cookies from "js-cookie";
	export default {
		name: 'DemandList',
		components: {
			Empty
		},
		data() {
			return {
				id: '',
				examplesData:{
					moduleId:'',
					rejectReason: '',
					status: '',
					type: ''
				},
				activities: [
					{
						message:'上级市场老总',
						operator:''
					},
					{
						message:'总部人事',
						operator:''
					},
				], // 流程列表
				dataInfo: {},
				postData: {
					id: '',
					submitType: '',
					modifyType: '',
					images: '',
					months: '',
					contents: [
						{
							id: '',
							assessId: '',
							computeMode: '',
							rater: '',
							raterName: '',
							remarks: '',
							source: '',
							target: '',
							targetValue: '',
							weight: ''
						}
					],
				},
				ticket:[],
				show: false,
				cos: {},
				month: '',
				monthArr: [],
				getStatus: '', // 判断是通过通知进来  还是通过列表进来
				noShow: false, // 驳回弹窗
				okShow: false, // 通过弹窗
				level: '', // 1 领导审批，2 人事审批
				stepId: 0, // 判断走到了哪一步
				nowLevel: ''
			}
		},
		created() {
			const query = this.$route.query
			if (!query.id) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			if (query.level) {
				this.level = query.level
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.id = query.id
			this.getInfo()
			// this.showApproval()
			this.initCOS()
		},
		mounted:function(){
		},
		methods: {
			// 获取信息
			getInfo(){
				const postData = {}
				postData.id = this.id
				getPerfById(postData).then((res) => {
					// 如果不在审批流范围 增加错误提示
					if (res.data.level === 3) {
						Dialog.alert({title: '提示', message: '您没有权限查看此任务！',})
						return false
					}
					this.dataInfo = res.data.assessForm
					this.activities = res.data.list
					this.nowLevel = res.data.level
					this.month = res.data.assessForm.years
					this.monthArr = res.data.assessForm.years.split("-")
					let tempData = sessionStorage.getItem('prefData')
					if(tempData){
						this.postData = JSON.parse(tempData)
					}else{
						this.postData.contents = res.data.detailFormList
						this.postData.id = res.data.assessForm.id
					}
					// 格式化图片链接
					if(this.dataInfo.images !== ''){
						const tempImg = JSON.parse(this.dataInfo.images)
						this.ticket = []
						for(let i = 0; i < tempImg.length; i++){
							let tempUrl = {}
							tempUrl.url = tempImg[i]
							tempUrl.isImage = true
							this.ticket.push(tempUrl)
						}
						console.log(this.ticket)
					}
					// 判断进行到了哪一步
					for (let i = 0; i < this.activities.length; i++) {
						if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
							this.stepId = i + 1
						}
					}
					console.log(this.stepId)
				})
			},
			// 去编辑
			toAddInfo(){
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.postData.images = JSON.stringify(tempFile)
				}
				sessionStorage.setItem('prefData',JSON.stringify(this.postData))
				const postData = {}
				postData.id = this.id
				this.$router.push({ path: '/addPref', query: postData})
			},
			// 展示退回弹窗
			returnData(){
				this.noShow = true
			},
			// 展示通过弹窗
			showOk(){
				this.okShow = true
			},
			// 提交拒绝
			toNo(type){
				this.examplesData.moduleId = this.id
				this.examplesData.type = 0
				this.examplesData.status = type
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.examplesData.images = JSON.stringify(tempFile)
				}
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				modifyFlow(this.examplesData).then(response => {
					console.log(response)
					Toast.clear()
					if(response){
						Dialog.alert({title: '提示', message: '操作成功',})
					}
					sessionStorage.removeItem('prefData')
					this.noShow = false
					this.okShow = false
					this.getInfo()
				}).catch(response => {
					console.log(response)
					Toast.clear()
				})
			},
			// 隐藏弹窗
			hideBox(){
				this.noShow = false
				this.showOverlay = false
			},
			// 提交数据
			addPerfData(){
				let _this = this
				this.postData.submitType = 1
				const tempFile = []
				for (let i = 0; i < this.ticket.length; i++) {
					tempFile.push(this.ticket[i].url)
				}
				if (tempFile.length > 0) {
					this.postData.images = JSON.stringify(tempFile)
				}
				this.postData.months = this.month
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				editPerf(this.postData).then(response => {
					Toast.clear()
					console.log(response)
					if(response){
						Dialog.alert({title: '提示', message: '操作成功',})
					}
					sessionStorage.removeItem('prefData')
					_this.getInfo()
				})
			},
			// 监听上传
			afterRead(file) {
				console.log(file);
				const typeArr = ['image/png', 'image/jpeg', 'image/jpg']
				const isJPG = typeArr.indexOf(file.file.type) !== -1
				if (!isJPG) {
					return
				}
				// 将文件上传至服务器
				this.toUpload(1, file)
			},
			// 初始化存储桶
			initCOS() {
				// 初始化实例
				const _this = this
				_this.cos = new COS({
					// 必选参数
					getAuthorization: function(options, callback) {
						console.log(222)
						getCosToken({}).then(response => {
							console.log(response)
							if (response.code === 200 || response.code === 0) {
								//  console.log(json)
								const credentials = response.data
								callback({
									TmpSecretId: credentials.tmpSecretId,
									TmpSecretKey: credentials.tmpSecretKey,
									XCosSecurityToken: credentials.sessionToken,
									// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
									StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
									ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900
									// ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
								})
							}
						})
					}
				})
			},
			// 获取随机数
			GetRandomNum(Min,Max) {
				var Range = Max - Min;
				var Rand = Math.random();
				return(Min + Math.round(Rand * Range));
			},
			// 提交上传
			toUpload(type, file) {
				const _this = this
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});
				this.cos.putObject({
					Bucket: 'tousubiaoyang-1301970825', /* 填入您自己的存储桶，必须字段 */
					Region: 'ap-nanjing', /* 存储桶所在地域，例如ap-beijing，必须字段 */
					Key: 'chartVideo/' + new Date().getTime() + this.GetRandomNum(10000,99999), /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
					StorageClass: 'STANDARD',
					Body: file.file, // 上传文件对象
					onProgress: function(progressData) {
						console.log(JSON.stringify(progressData))
					}
				}, function(err, data) {
					Toast.clear()
					console.log(err || data)
					let newUrl = data.Location.replace(/tousubiaoyang-1301970825.cos.ap-nanjing.myqcloud.com/, 'tousubiaoyang-1301970825.file.myqcloud.com')
					newUrl = 'https://' + newUrl
					_this.ticket[_this.ticket.length -1].url = newUrl
					console.log(_this.ticket)
				})
			}
		}
	}
</script>

<style scoped>
	.main{
		color:#333333;
		font-size: 15px;
	}
	.info-card-box{
		background: #FFFFFF;
		padding:15px;
	}
	.perf-info-box{
		margin: 10px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		font-weight: bold;
		font-size: 16px;
	}
	.ticket-title{
		font-weight: bold;
		margin-bottom: 10px;
	}
	.no-text{
		margin-bottom: 10px;
	}
	.card-line-box{
		display: flex;
		justify-content: flex-start;
		height: 30px;
		line-height: 30px;
	}
	.card-item-title{
		color: #666666 ;
	}
	.card-item-text{
		color: #333333 ;
	}
	.table-title-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.table-title-text{
		padding-left: 5px;
		color: #333333;
		font-size: 16px;
	}
	/*表格开始*/
	.table-box{
		margin: 15px 0;
		border-top: 1px solid #F6F6F6;
		border-left: 1px solid #F6F6F6;
		width: calc(100vw - 30px);
		border-radius: 1px;
	}
	.table-title-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #F1F4FD;
		color: #666666;
		height: 42px;
		line-height: 42px;
		text-align: center;
		font-size: 14px;
	}
	.table-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #000000;
		height: 42px;
		line-height: 42px;
		text-align: center;
	}
	.table-cell{
		border-right: 1px solid #F6F6F6;
		border-bottom: 1px solid #F6F6F6;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow:ellipsis;
		padding: 0 5px;
	}
	/*表格结束*/
	.add-button{
		width: 130px;
		height: 38px;
		line-height: 38px;
		font-size: 17px;
		background: #5A7DF6;
		border-radius: 4px;
		color: #ffffff;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
	}
	.add-button-icon{
		font-size: 28px;
		padding-right: 5px;
	}
	.add-button-text{
		font-size: 17px;
	}
	.bottom-button-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		display: flex;
		justify-content: space-around;
		padding: 30px 0;
		background-color: #ffffff;
		z-index: 999;
	}
	.button-no{
		width: 40vw;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: #FA7A6F;
		border-radius: 4px;
		color: #ffffff;
	}
	.button-ok{
		width: 40vw;
		height: 48px;
		line-height: 48px;
		text-align: center;
		background: #5A7DF6;
		border-radius: 4px;
		color: #ffffff;
	}
	.steps-title{
		font-size: 15px;
	}
	.leader-box{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		line-height: 30px;
	}
	.leader-item{
		/*padding: 5px 10px;*/
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.leader-item-box{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.leader-item-time{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: center;
		color: #666666;
	}
	.leader-item-name-box{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border-radius: 2px;
	}
	.leader-item-header-img{
		position: relative;
	}
	.step-ok-img{
		width: 10px;
		height: 10px;
		position: absolute;
		right: 2px;
		top: -3px;
	}
	.step-img{
		width: 20px;
		height: 20px;
		padding-right: 5px;
	}
	/*驳回弹窗*/
	.message-box-info-box{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	.message-box-text{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}
	.message-box-info-top-title{
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
	}
	.message-box-info-top-info{
		color: #666666;
		padding: 0 20px 20px 20px;
		text-align: center;
	}
	.button-ok-popup{
		color: #237FF0;
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		text-align: center;
		border-left: 2px solid #F1F1F1;
		border-top: 1px solid #F1F1F1;
		width: 40vw;
	}
	.button-cancel{
		color: #666666;
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		text-align: center;
		border-top: 1px solid #F1F1F1;
		width: 40vw;
	}
	/*驳回弹窗*/
	.leader-ok{
		/*color: #70e508;*/
		padding-right: 10px;
	}
	.leader-no{
		/*color: #f6404f;*/
		padding-right: 10px;
	}
	.back-msg{
		height: 32px;
		line-height: 32px;
	}
	.active-tip{
		color: #5A7DF6;
		border-radius: 2px;
		border: 1px solid #5A7DF6;
		padding: 2px 5px;
		margin-left: 10px;
	}
	/deep/ .van-uploader__upload{
		background-color: #ffffff !important;
	}
	/deep/ .van-uploader__upload-icon {
		font-size: 80px !important;
		display: flex;
		align-items: center;
	}
	/deep/ .van-step--vertical .van-step__circle-container{
		font-size: 20px !important;
	}
	/deep/ .van-step--vertical .van-step__line{
		top: 30px !important;
		height: 75% !important;
		border-left:1px dashed #ebedf0;
		background-color: #ffffff !important;
	}
</style>
